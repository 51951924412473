export default [
  {
    path: '/paymentsRecu',
    name: 'payments-recu-list',
    props: true,
    component: () => import('@/views/paymentsRecu/Index.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: "Liste des bons d'argent reçus",
      breadcrumb: [
        {
          text: 'argent reçus',
        },
        {
          text: "Liste des bons d'argent reçus",
          active: true,
        },
      ],
      roles: ['admin', 'manager'],
    },
  },
  {
    path: '/paymentsRecu/create',
    name: 'payments-recu-add',
    props: true,
    component: () => import('@/views/paymentsRecu/Create.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      pageTitle: "Ajouter bon d'argent reçus",
      breadcrumb: [
        {
          text: 'argent reçus',
        },
        {
          text: "Ajouter bon d'argent reçus",
          active: true,
        },
      ],
      roles: ['admin', 'manager'],
    },
  },
  {
    path: '/paymentsRecu/Show/:id',
    name: 'payments-recu-show',
    props: true,
    component: () => import('@/views/paymentsRecu/Show.vue'),
    meta: {
      admin: true,
      requireLogin: true,
      roles: ['admin', 'manager'],
    },
  },
]
